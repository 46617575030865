import React from "react"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

const ImagesPath = "../../assets/images"

const ImageParams = {
    formats: ["auto", "webp"],
    layout: "fullWidth",
    aspectRatio: 3 / 1,
}

function Image({ data, alt="", ...props }) {
    const img = getImage(data)
    return (
        <GatsbyImage image={img} alt={alt} {...props}/>
    )
}


function BgImg({ children, data, alt="", ...props }) {
    const img = getImage(data)
    const bgImg = convertToBgImage(img)
    return (
        <BackgroundImage {...bgImg} alt={alt}  {...props}>
            {children}
        </BackgroundImage>
    )
}

export { Image, BgImg, StaticImage, ImageParams, ImagesPath }