import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { motion, AnimatePresence } from 'framer-motion'
// import { useEventListener } from '@hooks/useEventListener'
import useVh from "@hooks/useVh"
// import { globalHistory } from '@reach/router'

// import ThreeJs from '@components/Three/Main.js'
// import Cursor from '@components/Cursor'
import Header from '@layouts/Header'
import Footer from "@layouts/Footer"
// import Loading from "@layouts/Loading"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'

import '@css/style.styl'


export function TransitionLayout({ children, location })
{
    useVh()
    let isPink = false
    if( location.pathname !== "/"){
        isPink = true
    }

    return (
        <div id="root-container" className="root-container">
            <Header isPink={isPink}/>
            {children}
            <Footer />
        </div>
    )
}
