import React from "react"
import { navigate } from 'gatsby-link'
import { globalHistory } from '@reach/router'
// import { useTransition } from '@layouts/TransitionLayout'
import { SmoothScroll } from '@hooks/useSmoothScroll'
// import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
// import { modalMenuState } from '@states/modal-menu-state'

// import { useStore } from '@hooks/useStore'
const Link = ({
	children,
	to,
	blank,
	scroll,
	onClick,
	...other
}) => {

	let isInternal = /^\/(?!\/)/.test(to)
	let isHash = /^(!?#)/.test(to)
	// const { appStore } = useStore()

	if (isHash || scroll) {
		isInternal = true
	}

	const { location } = globalHistory

	const onClickHandler = (e) => {
		e.stopPropagation()
		e.preventDefault()

		if (location.pathname === to || !to) {
			if (scroll) {
				// console.log(scroll)
				onClick && onClick()
				SmoothScroll(scroll)
			} else {
				onClick && onClick()
				SmoothScroll('#root-container')
			}
			return
		} else {
			onClick && onClick()
			navigate(`${to}`)
			if (scroll) {
				setTimeout(() => {
					SmoothScroll(scroll)
				}, 1000)
			}
		}
	}

	// useEffect(()=>{
	// })

	return (
		<>
		{ isInternal ?
				<a
					to={to}
					onClick={onClickHandler}
					{...other}
				>
					{children}
				</a>
			:
				<a href={to} target={blank && "_blank"} {...other}>
					{children}
				</a>
		}
		</>
	)
}
export { Link }