import React from "react"
import { Link } from "@atoms/Link"

export default function Menu({css, callback = () => {}})
{
    let menu = [
        {
            label: "トップ",
            url: '/',
            scroll: '#top'
        }, {
            label: "アカデミーの特長",
            url: '/',
            scroll: '#about'
        }, {
            label: "アカデミーTOPICKS",
            url: '/',
            scroll: '#topicks'
        }, {
            label: "講師紹介",
            url: '/',
            scroll: '#instructors'
        }, {
            label: "ワークショップ&スペシャルレッスン",
            url: '/',
            scroll: '#workshop'
        },  {
            label: "レッスン内容",
            url: '/',
            scroll: '#lessons'
        }, {
            label: "アカデミー紹介ムービー",
            url: '/',
            scroll: '#movies'
        }, {
            label: "練習生インタビュー",
            url: '/',
            scroll: '#interviews'
        }, {
            label: "入学オーディション",
            url: '/',
            scroll: '#audition'
        }, {
            label: "代表からのメッセージ",
            url: '/',
            scroll: '#message'
        }, {
            label: "アクセス",
            url: '/',
            scroll: '#access'
        }, {
            isSpace: true,
        }, {
            label: "個人情報保護方針（プライバシーポリシー）",
            url: "/privacy-policy/",
            className: 'small'
        }, {
            label: "特定商取引法に基づく表記",
            url: "/ebizrule/",
            className: 'small'
        }
    ]
    return(
            <ul>
                {menu.map((val, i) => {
                    let { label, url, isSpace, className, scroll } = val
                    if (isSpace) {
                        return (
                            <li key={i} className="space">
                            </li>
                        )
                    } else {
                        if( !url ){
                            return (
                                <li key={i} className={className || ''}>
                                    <Link scroll={scroll} onClick={callback}>{label}</Link>
                                </li>
                            )
                        } else {
                            return (
                                <li key={i} className={className || ''}>
                                    <Link to={url} scroll={scroll} onClick={callback}>{label}</Link>
                                </li>
                            )
                        }
                    }
                })}
            </ul>
    )
}
