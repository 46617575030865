import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ModalMenu from "@layouts/ModalMenu"
import { Link } from "@atoms/Link"
import { gsap, Power4, Elastic } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Image } from '@atoms/Image'

// import { Trigger } from "@atoms/AnimationTrigger"
// import { globalHistory } from '@reach/router'
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
// import LinkList from '@projects/LinkList'
// import { menuData } from '@utility/UrlData'
// import { Svg } from '@atoms/Svg'

import * as css from "@css/layouts/l-header.module.styl"
gsap.registerPlugin(ScrollTrigger)
// import LogoSvg from "@images/Header__logo__repaver.svg"

export default function Header({isPink})
{
    // const { appStore } = useStore()
    // const { location } = globalHistory
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "common__logo__special1.png" }) { publicURL }
            logoPink : file(relativePath: { eq: "common__logo__special1-pink.png" }) { publicURL }
            instagram : file(relativePath: { eq: "common__btn__instagram.png" }) { childImageSharp {
                ...SnsIcon
            } }
            tiktok : file(relativePath: { eq: "common__btn__tiktok.png" }) { childImageSharp {
                ...SnsIcon
            } }
            line : file(relativePath: { eq: "common__btn__line.png" }) { childImageSharp {
                ...SnsIcon
            } }
        }
    `)
    const ref = React.useRef()
    const [pink, setPink] = React.useState(false)
    React.useEffect(()=>{
        // let { y } = document.getElementById('top').getBoundingClientRect()
        if( isPink ) {
            setPink(true)
            return
        }
        gsap.to(ref.current, {
            scrollTrigger: {
                trigger: document.getElementById('top-movie'),
                start: `top end`,
                once: false,
                onToggle: ({ progress }) => {
                    if (progress) {
                        setPink(true)
                    } else {
                        setPink(false)
                    }
                }
            },
        })
    },[isPink])
    return(
        <header className={css.lHeader} id="header" ref={ref}>
            <div className={css.lHeader__inner}>
                <Link className={css.aLogo} to="/">
                    <img src={ !pink ? q.logo.publicURL : q.logoPink.publicURL} alt="SPECIAL1 ENTERTAINMENT ACADEMY"/>
                </Link>
            </div>
            <div className={css.lHeader__sns}>
                <Link to='https://www.instagram.com/special1academy/' blank>
                    <Image data={q.instagram.childImageSharp} />
                </Link>
                <Link to='https://www.tiktok.com/@special1academy?is_from_webapp=1&sender_device=pc' blank>
                    <Image data={q.tiktok.childImageSharp} />
                </Link>
                <Link to='https://lin.ee/gQIP8zj' blank>
                    <Image data={q.line.childImageSharp} />
                </Link>
            </div>
            <ModalMenu isPink={true}/>
        </header>
    )
}

