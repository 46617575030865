import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@atoms/Link"
import * as css from "@css/layouts/l-modal-menu.module.styl"
import Menu from "@projects/Menu"

export default function ModalMenu({isPink})
{
    const q = useStaticQuery(graphql`
        query {
            menu : file(relativePath: { eq: "common__btn__menu.svg" }) { publicURL }
            logo : file(relativePath: { eq: "common__logo__special1.svg" }) { publicURL }
            menuPink : file(relativePath: { eq: "common__btn__menu-pink.svg" }) { publicURL }
            logoPink : file(relativePath: { eq: "common__logo__special1-pink.svg" }) { publicURL }
        }
    `)

    const [isOpen, setIsOpen] = React.useState(false)
    const menuRef = React.useRef()
    React.useEffect(()=>{
        console.log('toggle')
    },[isOpen])

    const onClickHandler = () => {
        setIsOpen( !isOpen )
        console.log(isOpen)
    }
    const onEvent = () => {
        console.log('callback')
        setIsOpen(false)
        
    }
    React.useEffect(()=>{
        menuRef.current.style.display = isOpen ? 'block' : 'none'
    })
    return(
        <>
        <div className={isOpen ? css.lModalMenuOpen : css.lModalMenu }>
            <div className={css.lModalMenu__menu} ref={menuRef}>
                <div className={css.lModalMenu__menu__inner}>
                    <Link className={css.aLogo} to="/" onClick={onClickHandler}>
                        <img src={q.logo.publicURL} alt="SPECIAL1 ENTERTAINMENT ACADEMY" />
                    </Link>
                    <Menu callback={onEvent}/>
                </div>
            </div>
        </div>
        <button className={css.lModalMenu__button} onClick={onClickHandler}>
            <img src={isPink ? q.menuPink.publicURL : q.menu.publicURL}/>
        </button>
        </>
    )
}
