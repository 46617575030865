import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@atoms/Image"
import { Link } from "@atoms/Link"
import Menu from "@projects/Menu"
import * as css from "@css/layouts/l-footer.module.styl"

export default function Footer()
{  
    const q = useStaticQuery(graphql`
        query {
            title : file(relativePath: { eq: "footer__logo__special1.svg" }) {
                    publicURL
            }
            instagram : file(relativePath: { eq: "common__img__instagram.png" }) { childImageSharp {
                    ...SnsIcon
            } }
            twitter : file(relativePath: { eq: "common__img__twitter.png" }) { childImageSharp {
                    ...SnsIcon
            } }
            tictok : file(relativePath: { eq: "common__img__tictok.png" }) { childImageSharp {
                    ...SnsIcon
            } }
            line : file(relativePath: { eq: "common__img__line.png" }) { childImageSharp {
                    ...SnsIcon
            } }
            youtube : file(relativePath: { eq: "common__img__youtube.png" }) { childImageSharp {
                    ...SnsIcon
            } }
        }
    `)
    let sns = [
        {
            img: q.instagram,
            url: 'https://www.instagram.com/special1academy/'
        }, {
            img: q.twitter,
            url: 'https://twitter.com/AcademySpecial1'
        }, {
            img: q.tictok,
            url: 'https://www.tiktok.com/@special1academy?is_from_webapp=1&sender_device=pc'
        }, {
            img: q.line,
            url: 'https://lin.ee/gQIP8zj'
        }, {
            img: q.youtube,
            url: 'https://m.youtube.com/channel/UC0YIIYm8U7GL2Q-B4rH80GA'
        }
    ]
    return(
        <footer className={css.lFooter}>
            <div className={css.lFooter__inner}>
                <h2 className={css.lFooter__title}><img src={q.title.publicURL} alt="SPECIAL1 ENTERTAINMENT ACADEMY -スペシャルワン・エンターテインメント・アカデミー-" /></h2>
                <div className={css.lFooter__sns}>
                    <p>最新情報は各種SNSをフォロー！</p>
                    <ul>
                        { sns.map((val,i)=>{
                            let {img, url} = val
                            return(
                                <li key={i}>
                                    <Link to={url} blank>
                                        <Image data={img.childImageSharp} alt=""/>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className={css.lFooter__menu}>
                    <Menu />
                </div>
                <div className={css.lFooter__copyright}>
                    Copyright © {new Date().getFullYear()} WONDER&CLOCKS. All rights reserved.
                </div>
            </div>
        </footer>
    )
}