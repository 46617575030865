import React from "react"
import { useEventListener } from '@hooks/useEventListener'

export default function useVh()
{
    const vw = React.useRef(0)
    const setFillHeight = () => {
        // console.log('resize', vw.current, window.innerWidth)
        if (vw.current === window.innerWidth) {
            // 画面の横幅にサイズ変動がないので処理を終える
            return;
        }
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    React.useEffect(() => {
        setFillHeight()
    },[])

    useEventListener('resize', () => {
        setFillHeight()
        vw.current = window.innerWidth
    })
}
