// extracted by mini-css-extract-plugin
export var aLogo = "sp1nb";
export var lModalMenuOpen = "sp1ob";
export var lModalMenu = "sp1pb";
export var lModalMenuOpen__menu = "sp1qb";
export var lModalMenu__menu = "sp1rb";
export var lModalMenuOpen__menu__inner = "sp1sb";
export var lModalMenu__menu__inner = "sp1tb";
export var lModalMenuOpen__button = "sp1ub";
export var lModalMenu__button = "sp1vb";
export var animationMarquee = "sp1wb";